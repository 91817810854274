import { PermissionCheckerService, FeatureCheckerService } from 'abp-ng2-module';

import { Injectable } from '@angular/core';
import { AppMenu } from './app-menu';
import { AppMenuItem } from './app-menu-item';
import { Menu, MenuService } from '@delon/theme';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AppConsts } from '@shared/AppConsts';

@Injectable()
export class AppNavigationService {
    webSiteRootAddress = AppConsts.webSiteRootAddress ?? "/";
    constructor(
        private _permissionCheckerService: PermissionCheckerService,
        private _featureCheckerService: FeatureCheckerService,
        private _appSessionService: AppSessionService,
        private _localizationService: AppLocalizationService,
        private _ngAlainMenuService: MenuService,
    ) { }

    getMenu(): AppMenu {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem('Dashboard', 'Pages.Administration.Host.Dashboard', 'dashboard', '/app/admin/hostDashboard'),
            new AppMenuItem('Dashboard', 'Pages.Tenant.Dashboard', 'dashboard', '/app/main/dashboard'),
            new AppMenuItem('Tenants', 'Pages.Tenants', 'bars', '/app/admin/tenants'),
            new AppMenuItem('Editions', 'Pages.Editions', 'shopping', '/app/admin/editions'),

            new AppMenuItem('Quản lý tờ trình', 'Pages.QuanLyToTrinh', 'home', '', [
                new AppMenuItem('Công việc cần xử lý', 'Pages.QuanLyToTrinh.CongViec', 'check-circle', '/app/quan-ly-to-trinh/cong-viec'),
                new AppMenuItem('Văn bản đến', 'Pages.QuanLyToTrinh.VanBanDen', 'check-circle', '/app/quan-ly-to-trinh/van-ban-den'),
                new AppMenuItem('Văn bản chờ phân công', 'Pages.QuanLyToTrinh.VanBanChoDuyet', 'check-circle', '/app/quan-ly-to-trinh/van-ban-cho-duyet'),
                new AppMenuItem('Văn bản chờ tư vấn', 'Pages.QuanLyToTrinh.VanBanTuVan', 'check-circle', '/app/quan-ly-to-trinh/van-ban-cho-tu-van'),
                new AppMenuItem('Văn bản chờ phân công', 'Pages.QuanLyToTrinh.VanBanChoXuLy', 'check-circle', '/app/quan-ly-to-trinh/van-ban-cho-phan-cong'),
                new AppMenuItem('Biên soạn tờ trình', 'Pages.QuanLyToTrinh.BienSoanToTrinh', 'check-circle', '/app/quan-ly-to-trinh/van-ban-bien-soan'),
                new AppMenuItem('Tờ trình chờ duyệt', 'Pages.QuanLyToTrinh.ToTrinhChoPhoPhongDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-pho-phong-duyet'),
                new AppMenuItem('Tờ trình chờ duyệt', 'Pages.QuanLyToTrinh.ToTrinhChoTruongPhongDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-truong-phong-duyet'),
                new AppMenuItem('Tiếp nhận tờ trình chờ góp ý', 'Pages.QuanLyToTrinh.ToTrinhChoGopY', 'check-circle', '/app/quan-ly-to-trinh/van-ban-phong-ban-gop-y'),
                new AppMenuItem('Tờ trình chờ góp ý', 'Pages.QuanLyToTrinh.ToTrinhChoGopYChuyenVien', 'check-circle', '/app/quan-ly-to-trinh/van-ban-phong-ban-chuyen-vien-gop-y'),
                new AppMenuItem('Tờ trình chờ phê duyệt', 'Pages.QuanLyToTrinh.ToTrinhChoPhoGiamDocPheDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-pho-giam-doc-phe-duyet'),
                new AppMenuItem('Tờ trình chờ phê duyệt', 'Pages.QuanLyToTrinh.ToTrinhChoGiamDocPheDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-giam-doc-phe-duyet'),
                new AppMenuItem('Tờ trình chờ tư vấn', 'Pages.QuanLyToTrinh.ToTrinhChoTuVan', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-tu-van'),
                new AppMenuItem('Tờ trình đã phê duyệt', 'Pages.QuanLyToTrinh.ToTrinhDaPheDuyet', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-da-phe-duyet'),
                new AppMenuItem('Tờ trình chờ ban hành', 'Pages.QuanLyToTrinh.ToTrinhBanHanh', 'check-circle', '/app/quan-ly-to-trinh/to-trinh-cho-ban-hanh'),
                new AppMenuItem('Văn bản Admin quản lý', 'Pages.QuanLyToTrinh.QuyenAdminXuLy', 'check-circle', '/app/quan-ly-to-trinh/van-ban-admin-quan-ly'),
            ]),

            new AppMenuItem('Quản lý lịch họp', 'Pages.QuanLyLichHop', 'home', '', [
                new AppMenuItem('Lịch họp mới', 'Pages.QuanLyLichHop.LichHopMoi', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-moi'),
                new AppMenuItem('Lịch họp chờ duyệt', 'Pages.QuanLyLichHop.LichHopChoDuyet', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-duyet'),
                new AppMenuItem('Lịch họp chờ góp ý', 'Pages.QuanLyLichHop.LichHopChoGopY', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-gop-y'),
                new AppMenuItem('Lịch họp chờ tổng hợp', 'Pages.QuanLyLichHop.LichHopChoTongHop', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-tong-hop'),
                new AppMenuItem('Lịch họp chờ kết luận', 'Pages.QuanLyLichHop.LichHopChoKetLuan', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-ket-luan'),
                // new AppMenuItem('Lịch họp chờ tham gia', 'Pages.QuanLyLichHop.LichHopChoThamGia', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-cho-tham-gia'),
                // new AppMenuItem('Lịch họp đang diễn ra', 'Pages.QuanLyLichHop.LichHopDangDienRa', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-dang-dien-ra'),
                // new AppMenuItem('Lịch họp đã hoàn thành', 'Pages.QuanLyLichHop.LichHopDaHoanThanh', 'check-circle', '/app/quan-ly-lich-hop/lich-hop-da-hoan-thanh'),
            ]),

            new AppMenuItem('Ký điện tử', 'Pages.KyDienTu', 'home', '', [
                new AppMenuItem('Văn bản trình ký', 'Pages.KyDienTu.VanBanTrinhKy', 'check-circle', '/app/ky-dien-tu/van-ban-trinh-ky'),
                new AppMenuItem('Văn bản xét duyệt', 'Pages.KyDienTu.VanBanXetDuyet', 'check-circle', '/app/ky-dien-tu/van-ban-xet-duyet'),
                new AppMenuItem('Văn bản ký duyệt', 'Pages.KyDienTu.VanBanKyDuyet', 'check-circle', '/app/ky-dien-tu/van-ban-ky-duyet'),
                new AppMenuItem('Văn bản ký nháy', 'Pages.KyDienTu.VanBanKyNhay', 'check-circle', '/app/ky-dien-tu/van-ban-ky-nhay'),
                new AppMenuItem('Văn bản ban hành', 'Pages.KyDienTu.VanBanBanHanh', 'check-circle', '/app/ky-dien-tu/van-ban-ban-hanh'),
                new AppMenuItem('Quy trình ký mẫu', 'Pages.KyDienTu.QuyTrinhKyMau', 'setting', '/app/ky-dien-tu/quy-trinh-ky-mau'),
            ]),

            new AppMenuItem('Thư viện văn bản', 'Pages.ThuVienVanBan', 'home', '', [
                new AppMenuItem('Công bố văn bản', 'Pages.ThuVienVanBan.CongBoVanBan', 'check-circle', '/app/thu-vien-van-ban/cong-bo-van-ban'),
                new AppMenuItem('Tra cứu văn bản', 'Pages.ThuVienVanBan.TraCuuVanBan', 'check-circle', '/app/thu-vien-van-ban/tra-cuu-van-ban'),
            ]),

            new AppMenuItem('Danh mục', 'Pages.DanhMuc', 'bars', '', [
                new AppMenuItem('Địa chính', 'Pages.DanhMuc.DiaChinh', 'setting', '', [
                    new AppMenuItem('Quốc gia', 'Pages.DanhMuc.QuocTich', 'check-circle', '/app/danh-muc/quoc-gia'),
                    new AppMenuItem('Tỉnh', 'Pages.DanhMuc.Tinh', 'check-circle', '/app/danh-muc/tinh'),
                    new AppMenuItem('Huyện', 'Pages.DanhMuc.Huyen', 'check-circle', '/app/danh-muc/huyen'),
                    new AppMenuItem('Xã', 'Pages.DanhMuc.Xa', 'check-circle', '/app/danh-muc/xa'),
                ]),
                new AppMenuItem('Cây danh mục', 'Pages.DanhMuc.CayDanhMuc', 'check-circle', '/app/danh-muc/danh-muc-chung'),
                new AppMenuItem('Phòng ban', 'Pages.DanhMuc.PhongBan', 'check-circle', '/app/danh-muc/phong-ban'),
                new AppMenuItem('Chức vụ', 'Pages.DanhMuc.ChucVu', 'check-circle', '/app/danh-muc/chuc-vu'),
            ]),

            new AppMenuItem('Hệ thống', 'Pages.HeThong', 'home', '', [
                new AppMenuItem('Cài đặt', 'Pages.HeThong.CaiDat', 'setting', '/app/he-thong/cai-dat'),
                new AppMenuItem('Tài khoản người dùng', 'Pages.HeThong.QuanLyTaiKhoan', 'user', '/app/he-thong/tai-khoan-nguoi-dung'),
                new AppMenuItem('Thiết lập chữ ký số', 'Pages.HeThong.ThietLapChuKySo', 'setting', '/app/he-thong/thiet-lap-chu-ky-so'),
                new AppMenuItem('Thiết lập chung', 'Pages.HeThong.ThietLapChung', 'setting', '/app/he-thong/thiet-lap-chung'),
                new AppMenuItem('Thiết lập domain', 'Pages.HeThong.DomainRoute', 'setting', '/app/he-thong/thiet-lap-domain'),
                new AppMenuItem('Tài liệu hướng dẫn sử dụng', 'Pages.HeThong.TaiLieuHuongDan', 'book', '/app/he-thong/tai-lieu-huong-dan'),
                new AppMenuItem('Danh sách gửi mail', 'Pages.HeThong.DanhSachGuiMail', 'book', '/app/he-thong/danh-sach-gui-mail'),
            ]),

            new AppMenuItem('Administration', 'Pages.Administration', 'appstore', '', [
                new AppMenuItem('OrganizationUnits', 'Pages.Administration.OrganizationUnits', 'team', '/app/admin/organization-units'),
                new AppMenuItem('Roles', 'Pages.Administration.Roles', 'safety', '/app/admin/roles'),
                new AppMenuItem('Users', 'Pages.Administration.Users', 'user', '/app/admin/users'),
                new AppMenuItem('Languages', 'Pages.Administration.Languages', 'global', '/app/admin/languages'),
                new AppMenuItem('LanguageTexts', 'Pages.Administration.LanguageTexts', 'global', '/app/admin/languagetexts'),
                new AppMenuItem('AuditLogs', 'Pages.Administration.AuditLogs', 'book', '/app/admin/auditLogs'),
                new AppMenuItem('Maintenance', 'Pages.Administration.Host.Maintenance', 'lock', '/app/admin/maintenance'),
                // new AppMenuItem('Subscription', 'Pages.Administration.Tenant.SubscriptionManagement', 'flaticon-refresh', '/app/admin/subscription-management'),
                // new AppMenuItem('VisualSettings', 'Pages.Administration.UiCustomization', 'flaticon-medical', '/app/admin/ui-customization'),
                new AppMenuItem('Settings', 'Pages.Administration.Host.Settings', 'setting', '/app/admin/hostSettings'),
                new AppMenuItem('Settings', 'Pages.Administration.Tenant.Settings', 'setting', '/app/admin/tenantSettings')
            ]),
            new AppMenuItem('Hướng dẫn sử dụng', 'Pages.HuongDanSuDung', 'check-circle', '/app/he-thong/xem-huong-dan-su-dung'),
        ]);
    }

    mapToNgAlainMenu() {
        let menu = this.getMenu();
        let ngAlainRootMenu = <Menu>{
            text: this._localizationService.l(menu.name),
            group: false,
            hideInBreadcrumb: true,
            children: [],
        };
        this.generateNgAlainMenus(ngAlainRootMenu.children, menu.items);

        let ngAlainMenus = [ngAlainRootMenu];
        this._ngAlainMenuService.add(ngAlainMenus);
    }

    generateNgAlainMenus(ngAlainMenus: Menu[], appMenuItems: AppMenuItem[]) {
        appMenuItems.forEach((item) => {
            let ngAlainMenu: Menu;

            ngAlainMenu = {
                text: this._localizationService.l(item.name),
                link: item.route,
                icon: {
                    type: 'icon',
                    value: item.icon,
                },
                hide: !this.showMenuItem(item),
            };

            if (item.items && item.items.length > 0) {
                ngAlainMenu.children = [];
                this.generateNgAlainMenus(ngAlainMenu.children, item.items);
            }

            ngAlainMenus.push(ngAlainMenu);
        });
    }

    checkChildMenuItemPermission(menuItem): boolean {
        for (let i = 0; i < menuItem.items.length; i++) {
            let subMenuItem = menuItem.items[i];

            if (subMenuItem.permissionName && this._permissionCheckerService.isGranted(subMenuItem.permissionName)) {
                return true;
            }

            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            } else if (!subMenuItem.permissionName) {
                return true;
            }
        }

        return false;
    }

    showMenuItem(menuItem: AppMenuItem): boolean {
        if (
            menuItem.permissionName === 'Pages.Administration.Tenant.SubscriptionManagement' &&
            this._appSessionService.tenant &&
            !this._appSessionService.tenant.edition
        ) {
            return false;
        }

        let hideMenuItem = false;

        if (menuItem.requiresAuthentication && !this._appSessionService.user) {
            hideMenuItem = true;
        }

        if (menuItem.permissionName && !this._permissionCheckerService.isGranted(menuItem.permissionName)) {
            hideMenuItem = true;
        }

        if (menuItem.hasFeatureDependency() && !menuItem.featureDependencySatisfied()) {
            hideMenuItem = true;
        }

        if (hideMenuItem && menuItem.items && menuItem.items.length) {
            return this.checkChildMenuItemPermission(menuItem);
        }

        return !hideMenuItem;
    }
}
