import { AbpHttpInterceptor, RefreshTokenService, AbpHttpConfigurationService } from 'abp-ng2-module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';
import { ZeroRefreshTokenService } from '@account/auth/zero-refresh-token.service';
import { ZeroTemplateHttpConfigurationService } from './zero-template-http-configuration.service';

@NgModule({
    providers: [
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.CachingServiceProxy,
        ApiServiceProxies.CommonLookupServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.EditionServiceProxy,
        ApiServiceProxies.HostSettingsServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.LanguageServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.PermissionServiceProxy,
        ApiServiceProxies.ProfileServiceProxy,
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.TenantDashboardServiceProxy,
        ApiServiceProxies.TenantSettingsServiceProxy,
        ApiServiceProxies.TimingServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.UserLinkServiceProxy,
        ApiServiceProxies.UserLoginServiceProxy,
        ApiServiceProxies.WebLogServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.TenantRegistrationServiceProxy,
        ApiServiceProxies.HostDashboardServiceProxy,
        ApiServiceProxies.InstallServiceProxy,
        ApiServiceProxies.DanhMucHuyenServiceProxy,
        ApiServiceProxies.DanhMucTinhServiceProxy,
        ApiServiceProxies.DanhMucXaServiceProxy,
        ApiServiceProxies.PhongBanServiceProxy,
        ApiServiceProxies.ComboBaseServiceProxy,
        ApiServiceProxies.CommonServiceProxy,
        ApiServiceProxies.ChuKyServiceProxy,
        ApiServiceProxies.DanhMucQuocTichServiceProxy,
        ApiServiceProxies.TaiLieuHuongDanServiceProxy,
        ApiServiceProxies.ThietLapChungServiceProxy,
        ApiServiceProxies.KySoServiceProxy,
        ApiServiceProxies.LstDictionaryServiceProxy,
        ApiServiceProxies.LstDictionaryTypeServiceProxy,
        ApiServiceProxies.QuyTrinhKyServiceProxy,
        ApiServiceProxies.TaiKhoanServiceProxy,
        ApiServiceProxies.VanBanServiceProxy,
        ApiServiceProxies.VanBanDenServiceProxy,
        ApiServiceProxies.VanBanChoDuyetServiceProxy,
        ApiServiceProxies.VanBanChoTuVanServiceProxy,
        ApiServiceProxies.VanBanChoPhanCongServiceProxy,
        ApiServiceProxies.VanBanBienSoanServiceProxy,
        ApiServiceProxies.ToTrinhPhoPhongDuyetServiceProxy,
        ApiServiceProxies.ToTrinhTruongPhongDuyetServiceProxy,
        ApiServiceProxies.ToTrinhPhongBanGopYServiceProxy,
        ApiServiceProxies.ToTrinhChuyenVienGopYServiceProxy,
        ApiServiceProxies.ToTrinhChoPhoGiamDocPheDuyetServiceProxy,
        ApiServiceProxies.ToTrinhChoGiamDocPheDuyetServiceProxy,
        ApiServiceProxies.ToTrinhChoBanHanhServiceProxy,
        ApiServiceProxies.ChucVuServiceProxy,
        ApiServiceProxies.CongViecCanXuLyServiceProxy,
        ApiServiceProxies.ThietLapDomainServiceProxy,
        ApiServiceProxies.ToTrinhDaPheDuyetServiceProxy,

        //Ký điện tử

        ApiServiceProxies.ToTrinhServiceProxy,
        ApiServiceProxies.ToTrinhXetDuyetServiceProxy,
        ApiServiceProxies.ToTrinhBanHanhServiceProxy,
        ApiServiceProxies.ToTrinhGopYServiceProxy,

        //Dashboard
        ApiServiceProxies.DashboardServiceProxy,

        //Ký điện tử

        //quản lý lịch họp
        ApiServiceProxies.LichHopServiceProxy,
        ApiServiceProxies.LichHopMoiServiceProxy,
        ApiServiceProxies.LichHopChoDuyetServiceProxy,
        ApiServiceProxies.LichHopGopYServiceProxy,
        ApiServiceProxies.LichHopTongHopServiceProxy,
        ApiServiceProxies.LichHopKetLuanServiceProxy,

        //Thư viện văn bản
        ApiServiceProxies.CongBoVanBanServiceProxy,
        ApiServiceProxies.TraCuuVanBanCongBoServiceProxy,
        ApiServiceProxies.QuanLyGuiMailServiceProxy,

        //ApiServiceProxies.DashboardServiceProxy,
        { provide: RefreshTokenService, useClass: ZeroRefreshTokenService },
        { provide: AbpHttpConfigurationService, useClass: ZeroTemplateHttpConfigurationService },
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
